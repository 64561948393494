
import { Component, Vue } from "vue-property-decorator";
import { Action, Mutation, Getter } from "vuex-class";
import { Mixins } from "vue-mixin-decorator";
import GeneralMixin from "@/mixin";
import { ISelectItem } from "@/types";
import { IMaker, IMakerListRequest } from "@/types/maker";
import { ICustomerListRequest, ICustomer } from "@/types/customer";
import { IBranchListRequest, IBranch } from "@/types/branch";
import { IProductListRequest, IProduct } from "@/types/product";
import { IReceivedOrderCreateRequest } from "@/types/received_order";
import { IProductReceivedOrder } from "@/types/product_received_order";
import { ProductReceivedOrder } from "@/models/product_received_order";
import { PriceTagReceivedOrder } from "@/models/price_tag_received_order";
import { Orders } from "@/models/orders";
import { ReceivedOrderCreateRequest } from "@/models/received_order";
import { IReceivedOrdersManagement } from "@/types/received_orders_management";
import {
  ICustomerProduct,
  ICustomerProductListRequest
} from "@/types/customer_product";
import { IPriceTag, IPriceTagListRequest } from "@/types/price_tag";
import { ICustomerPriceTagListRequest } from "@/types/customer_price_tag";

@Component({})
export default class extends Mixins<GeneralMixin>(GeneralMixin) {
  //received_order
  @Action("received_order/adminCreate")
  public create!: (params: IReceivedOrderCreateRequest) => Promise<boolean>;

  //received_orders_management
  @Action("received_orders_management/adminCreate")
  public receivedOrdersManagementCreate!: () => Promise<boolean>;

  @Getter("received_orders_management/single")
  public received_orders_management!: IReceivedOrdersManagement;

  //customer
  @Action("customer/adminGetList")
  public getCustomers!: (params: ICustomerListRequest) => Promise<boolean>;

  @Getter("customer/selectItem")
  public customerList!: ISelectItem[];

  @Getter("customer/find")
  public findCustomer!: (id: number) => ICustomer;

  @Getter("customer/findName")
  public findCustomerName!: (name: string) => ICustomer;

  @Mutation("customer/clear")
  public clearCustomer!: () => void;

  //branch
  @Action("branch/adminGetList")
  public getBranches!: (params: IBranchListRequest) => Promise<boolean>;

  @Getter("branch/selectItem")
  public branchList!: ISelectItem[];

  @Getter("branch/find")
  public findBranch!: (id: number) => IBranch;

  @Mutation("branch/clear")
  public clearBranch!: () => void;

  //maker
  @Action("maker/adminGetList")
  public getMakers!: (params: IMakerListRequest) => Promise<boolean>;

  @Getter("maker/selectItem")
  public makerList!: ISelectItem[];

  @Getter("maker/find")
  public findMaker!: (id: number) => IMaker;

  @Mutation("maker/clear")
  public clearMaker!: () => void;

  //product
  @Action("product/adminGetList")
  public getProducts!: (params: IProductListRequest) => Promise<boolean>;

  @Action("product/adminGetProductSegments")
  public getProductSegments!: (params: IProductListRequest) => Promise<boolean>;

  @Getter("product/filterdSelectItem")
  public makerFilterdSelectItem!: (maker_id: number) => ISelectItem[];

  @Getter("product/find")
  public findProduct!: (id: number) => IProduct;

  @Getter("product/findCode")
  public findProductCode!: (code: string) => IProduct;

  @Mutation("product/clear")
  public clearProduct!: () => void;

  // customer_product
  @Action("customer_product/adminGetList")
  public getCustomerProducts!: (
    params: ICustomerProductListRequest
  ) => Promise<boolean>;

  @Getter("customer_product/filterdSelectItem")
  public customerProductFilterdSelectItem!: (
    customer_id: number,
    product_id: number
  ) => ISelectItem[];

  @Getter("customer_product/find")
  public findCustomerProduct!: (id: number) => ICustomerProduct;

  @Getter("customer_product/findCustomerPn")
  public findCustomerPn!: (customer_pn: string) => ICustomerProduct;

  @Mutation("customer_product/clear")
  public clearCustomerProduct!: () => void;

  // price_tag
  @Action("price_tag/adminGetList")
  public getPriceTags!: (request: IPriceTagListRequest) => Promise<boolean>;

  @Getter("price_tag/selectItem")
  public priceTagList!: ISelectItem[];

  @Getter("price_tag/find")
  public findPriceTag!: (id: number) => IPriceTag;

  @Mutation("price_tag/clear")
  public clearPriceTag!: () => void;

  //customer_price_tag
  @Action("customer_price_tag/adminGetList")
  public getCustomerPriceTags!: (
    params: ICustomerPriceTagListRequest
  ) => Promise<boolean>;

  @Getter("customer_price_tag/filterdSelectItem")
  public customerPriceTagFilterdSelectItem!: (
    customer_id: number,
    price_tag_id: number
  ) => ISelectItem[];

  @Getter("customer_price_tag/find")
  public findCustomerPriceTag!: (id: number) => ICustomerProduct;

  @Mutation("customer_price_tag/clear")
  public clearCustomerPriceTag!: () => void;

  //変数定義
  public received_order_status = Vue.prototype.$receivedOrderStatus;
  public maker_ids: number[][] = [[]];
  public productOptions: ISelectItem[][] = [[]];
  public customerProductOptions: ISelectItem[][][] = [[]];
  public customerPriceTagOptions: ISelectItem[][][] = [[]];
  public units: (string | null)[][] = [[]];
  public isCode: string[][] = [[]];
  public isCustomerPn: string[][] = [[]];
  public is_disabled: boolean[][] = [[true]];
  public is_readonly: boolean[][] = [[false]];
  public is_disabled_price_tag: boolean[][] = [[true]];
  public is_readonly_price_tag: boolean[][] = [[false]];
  public is_minus: number[][] = [[]];
  public isCustomer = true;
  public submit_dialog = false;
  public valid = true;
  public valid_product_received_order = true;
  public lazy = false;
  public menu = {
    order_date: false
  };
  public desired_delivery_date_menu = [false];
  public fixed_delivery_date_menu = [false];

  //ルール設定
  public rules = {
    order_no: [
      (v: string) => !!v || "顧客注文番号が無い場合はメモ等を入力してください"
    ],
    order_date: [(v: string) => !!v || "受注受付日は必須です"],
    customer_id: [(v: number) => !!v || "顧客は必須です"],
    branch_id: [(v: number) => !!v || "支店は必須です"],
    maker_id: [(v: number) => !!v || "メーカは必須です"],
    quantity: [
      (v: number) => !!v || "数量は必須です",
      (v: number) => v > 0 || "0以下は入力できません",
      (v: string) => /^([1-9]\d*|0)$/.test(v) || "整数で入力してください"
    ],
    selling_unit_price: [
      (v: number) => !!v || "単価は必須です",
      (v: number) => v >= 0 || "0未満は入力できません",
      (v: string) =>
        /^([1-9]\d*|0)(\.\d{1,2})?$/.test(v) ||
        "小数点以下2桁までで入力してください"
    ]
  };

  //パラメータ定義
  public params: IReceivedOrderCreateRequest = new ReceivedOrderCreateRequest();

  //--------
  // コンポーネント作成時実行
  public async created() {
    this.clearCustomer();
    this.clearBranch();
    this.clearMaker();
    this.clearProduct();
    this.clearCustomerProduct();
    this.clearPriceTag();
    this.clearCustomerPriceTag();

    this.params.order_status = this.received_order_status.new_received_order;

    await Promise.all([
      this.getCustomers({ per_page: 0 }),
      this.getMakers({ per_page: 0 }),
      this.getCustomerProducts({ per_page: 0 }),
      this.getPriceTags({ per_page: 0 }),
      this.getCustomerPriceTags({ per_page: 0 })
    ]);

    this.$nextTick(function () {
      (
        this.$refs.received_order_form as Vue & { validate: () => boolean }
      ).validate();
      (
        this.$refs.product_received_order_form as Vue & {
          validate: () => boolean;
        }
      ).validate();
    });
  }

  //バリデーションチェック
  public updated() {
    this.$nextTick(function () {
      (
        this.$refs.product_received_order_form as Vue & {
          validate: () => boolean;
        }
      ).validate();
    });
  }

  // 登録確認画面
  public submitConfirm() {
    this.submit_dialog = true;
  }

  //--------
  // 確定ボタンが押された際の挙動
  public async submit() {
    this.submit_dialog = false;
    let result = false;

    //諸経費配列の整理
    for (let [, order] of this.params.orders.entries()) {
      if (
        !order.price_tag_received_orders.some(item => item.price_tag_id > 0)
      ) {
        order.price_tag_received_orders.splice(0);
      } else {
        order.price_tag_received_orders =
          order.price_tag_received_orders.filter(item => {
            return item.price_tag_id;
          });
      }
    }

    //受注管理番号作成
    await this.receivedOrdersManagementCreate();

    this.params.received_orders_management_id =
      this.received_orders_management.id;

    result = await this.create(this.params);

    if (result) {
      this.$router.go(-1);
    }
  }

  //顧客が選択された際の挙動
  public async customerSelected() {
    this.clearBranch();
    this.params.branch_id = null;
    this.isCustomer = true;

    if (this.params.customer_id) {
      await this.getBranches({
        customer_id: this.params.customer_id,
        per_page: 0
      });

      this.isCustomer = false;

      this.params.orders.forEach((order, index_order) => {
        order.product_received_orders.forEach(
          (product_received_order, index: number) => {
            if (product_received_order.product_id) {
              this.productSelected(index_order, index);
            }
          }
        );
      });

      this.params.orders.forEach((order, index_order) => {
        order.price_tag_received_orders.forEach(
          (price_tag_received_order, index) => {
            if (price_tag_received_order.price_tag_id) {
              this.priceTagSelected(index_order, index);
            }
          }
        );
      });
    }
  }

  //メーカが選択された際の挙動
  public async makerSelected(index_order: number, index: number) {
    this.params.orders[index_order].product_received_orders[
      index
    ].product_id = 0;
    this.params.orders[index_order].product_received_orders[
      index
    ].selling_unit_price = null;
    this.params.orders[index_order].product_received_orders[index].quantity =
      null;
    this.params.orders[index_order].product_received_orders[index].amount = 0;
    this.params.orders[index_order].product_received_orders[index].content = "";
    this.params.orders[index_order].product_received_orders[
      index
    ].customer_product_id = null;

    await this.getProductSegments({
      maker_id: this.maker_ids[index_order][index],
      per_page: 0
    });
    this.$set(
      this.productOptions[index_order],
      index,
      this.makerFilterdSelectItem(this.maker_ids[index_order][index])
    );
  }

  //商品が選択された際の挙動
  public productSelected(index_order: number, index: number) {
    this.params.orders[index_order].product_received_orders[
      index
    ].customer_product_id = null;
    const product = this.findProduct(
      this.params.orders[index_order].product_received_orders[index].product_id
    );

    this.params.orders[index_order].product_received_orders[
      index
    ].selling_unit_price = product
      ? product.unit_price
        ? Number(product.unit_price.toFixed(2))
        : null
      : null;

    this.units[index_order][index] = product ? product.unit : null;

    this.$set(
      this.customerProductOptions[index_order],
      index,
      this.customerProductFilterdSelectItem(
        this.params.customer_id,
        this.params.orders[index_order].product_received_orders[index]
          .product_id
      )
    );

    this.is_disabled[index_order][index] =
      Object.keys(this.customerProductOptions[index_order][index]).length == 0;

    this.is_readonly[index_order][index] =
      Object.keys(this.customerProductOptions[index_order][index]).length == 1;

    if (this.is_readonly[index_order][index]) {
      this.params.orders[index_order].product_received_orders[
        index
      ].customer_product_id = Number(
        this.customerProductOptions[index_order][index][0].value
      );
    }
  }

  //諸経費が選択された際の挙動
  public priceTagSelected(index_order: number, index_price_tag: number) {
    this.$set(
      this.customerPriceTagOptions[index_order],
      index_price_tag,
      this.customerPriceTagFilterdSelectItem(
        this.params.customer_id,
        this.params.orders[index_order].price_tag_received_orders[
          index_price_tag
        ].price_tag_id
      )
    );
    this.is_disabled_price_tag[index_order][index_price_tag] =
      Object.keys(this.customerPriceTagOptions[index_order][index_price_tag])
        .length == 0;

    this.is_readonly_price_tag[index_order][index_price_tag] =
      Object.keys(this.customerPriceTagOptions[index_order][index_price_tag])
        .length == 1;

    if (this.is_readonly_price_tag[index_order][index_price_tag]) {
      this.params.orders[index_order].price_tag_received_orders[
        index_price_tag
      ].customer_price_tag_id = Number(
        this.customerPriceTagOptions[index_order][index_price_tag][0].value
      );
    }
  }

  //小計金額計算
  public amount(index_order: number) {
    let amount = 0;
    this.params.orders[index_order].product_received_orders.forEach(
      product_received_order =>
        (amount += product_received_order.product_amount)
    );

    this.params.orders[index_order].price_tag_received_orders.forEach(
      (price_tag, index) => {
        this.is_minus[index_order][index]
          ? (amount -= Number(price_tag.price))
          : (amount += Number(price_tag.price));
      }
    );

    this.params.orders[index_order].amount = Number(amount.toFixed(2));
  }

  //商品合計金額計算(バックエンド側で計算する場合は不要)
  public calcAmount(
    index_order: number,
    index: number,
    product_received_orders: IProductReceivedOrder
  ) {
    this.params.orders[index_order].product_received_orders[index].amount =
      Number(product_received_orders.product_amount.toFixed(2));
  }

  //顧客注文追加
  public appendOrderList() {
    this.params.orders.push(new Orders());
    const index_order = this.params.orders.length - 1;
    //一つ前の顧客注文をコピー
    this.params.orders[index_order].order_no =
      this.params.orders[index_order - 1].order_no;
    this.params.orders[index_order].is_asap =
      this.params.orders[index_order - 1].is_asap;
    this.params.orders[index_order].desired_delivery_date =
      this.params.orders[index_order - 1].desired_delivery_date;
    this.params.orders[index_order].fixed_delivery_date =
      this.params.orders[index_order - 1].fixed_delivery_date;
    this.params.orders[index_order].destination =
      this.params.orders[index_order - 1].destination;
    //-----------------------
    this.maker_ids.push([]);
    this.units.push([]);
    this.productOptions.push([]);
    this.customerProductOptions.push([]);
    this.customerPriceTagOptions.push([]);
    this.isCode.push([]);
    this.isCustomerPn.push([]);
    this.is_disabled.push([true]);
    this.is_readonly.push([false]);
    this.is_disabled_price_tag.push([true]);
    this.is_readonly_price_tag.push([false]);
    this.is_minus.push([]);
  }

  //顧客注文削除
  public deleteOrderList(index_order: number) {
    this.maker_ids.splice(index_order, 1);
    this.productOptions.splice(index_order, 1);
    this.customerProductOptions.splice(index_order, 1);
    this.units.splice(index_order, 1);
    this.isCode.splice(index_order, 1);
    this.isCustomerPn.splice(index_order, 1);
    this.is_disabled.splice(index_order, 1);
    this.is_readonly.splice(index_order, 1);
    this.is_disabled_price_tag.splice(index_order, 1);
    this.is_readonly_price_tag.splice(index_order, 1);
    this.params.orders.splice(index_order, 1);
    this.is_minus.splice(index_order, 1);
  }

  //受注商品追加
  public appendProductReceivedOrderList(index_order: number) {
    this.params.orders[index_order].product_received_orders.push(
      new ProductReceivedOrder()
    );
    this.is_disabled[index_order].push(true);
    this.is_readonly[index_order].push(false);
  }

  //受注商品削除
  public deleteProductReceivedOrderList(index_order: number, index: number) {
    this.params.orders[index_order].product_received_orders.splice(index, 1);
    this.maker_ids[index_order].splice(index, 1);
    this.productOptions[index_order].splice(index, 1);
    this.customerProductOptions[index_order].splice(index, 1);
    this.units[index_order].splice(index, 1);
    this.isCode[index_order].splice(index, 1);
    this.isCustomerPn[index_order].splice(index, 1);
    this.is_disabled[index_order].splice(index, 1);
    this.is_readonly[index_order].splice(index, 1);
  }

  //諸経費追加
  public appendPriceTagReceivedOrderList(index_order: number) {
    this.params.orders[index_order].price_tag_received_orders.push(
      new PriceTagReceivedOrder()
    );
    this.is_disabled_price_tag[index_order].push(true);
    this.is_readonly_price_tag[index_order].push(false);
  }

  //諸経費削除
  public deletePriceTagReceivedOrderList(
    index_order: number,
    index_price_tag: number
  ) {
    this.params.orders[index_order].price_tag_received_orders.splice(
      index_price_tag,
      1
    );
    this.customerPriceTagOptions[index_order].splice(index_price_tag, 1);
    this.is_disabled_price_tag[index_order].splice(index_price_tag, 1);
    this.is_readonly_price_tag[index_order].splice(index_price_tag, 1);
    this.is_minus[index_order].splice(index_price_tag, 1);
  }

  //productバリデーション
  public productRule(
    v: number,
    product_received_orders: IProductReceivedOrder[]
  ) {
    const countSameProductId = product_received_orders.filter(
      product_received_order => product_received_order.product_id == v
    ).length;

    if (countSameProductId >= 2) {
      return "同じ商品は選択できません";
    }

    return true;
  }

  //price_tagのis_minusチェック
  public isMinusCheck(
    price_tag_id: number,
    index_order: number,
    index_price_tag: number
  ) {
    this.is_minus[index_order][index_price_tag] = price_tag_id
      ? this.findPriceTag(price_tag_id).is_minus
      : 0;
  }

  //price_tag_idバリデーション
  public prriceTagRule(v: number, index_order: number) {
    const countSamePriceTagId = this.params.orders[
      index_order
    ].price_tag_received_orders.filter(
      price_tag => price_tag.price_tag_id == v
    ).length;

    if (countSamePriceTagId >= 2) {
      return "同じ諸経費名は選択できません";
    }

    return true;
  }

  //priceバリデーション
  public priceRule(v: number) {
    if (v < 0) {
      return "0以下は入力できません";
    }

    if (!/^([1-9]\d*|0)(\.\d{1,2})?$/.test(String(v))) {
      return "小数点以下2桁までで入力してください";
    }

    return true;
  }

  //csv取り込み
  public async fileChange(e: Blob) {
    //初期化
    this.deleteAllOrderList();
    if (!e) return;
    //csvファイル取得
    const file = e;
    if (!file.type.match("text/csv")) {
      alert("CSVファイルを選択してください");
      return;
    }
    //FileReaderインスタンス生成
    const reader = new FileReader();

    //品番から商品を絞るためにproductを全件取得
    await this.getProducts({ per_page: 0 });

    //取り込み処理
    const loadFunc = () => {
      let index_order = 0;
      let index_product_received_order = 0;
      //改行で配列化
      const lines = String(reader.result).split("\n");
      if (!lines[0].match("顧客名")) {
        alert("ファイル内容と入力項目が一致しません");
        return;
      }
      //ヘッダー削除
      lines.shift();
      //paramsへのデータ入力処理
      lines.forEach(element => {
        //カンマで配列化
        const receivedOrderData = element.split(",");
        //正しいcsvファイルかチェック
        if (receivedOrderData.length != 10) {
          alert("ファイル内容と入力項目が一致しません");
          throw "Wrong file";
        }

        //received_order部分入力
        //顧客入力
        if (!this.params.customer_id) {
          const customer = this.findCustomerName(receivedOrderData[0]);
          this.params.customer_id = customer ? customer.id : 0;
          this.isCustomer = !this.params.customer_id ? true : false;
          this.getBranches({
            customer_id: this.params.customer_id,
            per_page: 0
          });
        }

        //orders部分入力
        if (
          !this.params.orders[0].order_no ||
          this.params.orders[index_order - 1].order_no != receivedOrderData[1]
        ) {
          //顧客注文追加
          if (index_order != 0) {
            this.appendOrderListCsv();
          }
          //顧客注文番号入力
          this.params.orders[index_order].order_no = receivedOrderData[1];
          //希望納品日入力
          this.params.orders[index_order].desired_delivery_date =
            this.initializeDateForCsv(receivedOrderData[7]);
          //納品先入力
          this.params.orders[index_order].destination = receivedOrderData[9];
          //index_orderカウント
          index_order += 1;
          //index_product_received_orderリセット
          index_product_received_order = 0;
        }
        //商品選択追加
        if (index_product_received_order != 0) {
          this.appendProductReceivedOrderList(index_order - 1);
        }

        //product_received_order部分入力
        //品番でproductを絞り込み
        const product = this.findProductCode(receivedOrderData[3]);
        const customer_product = this.findCustomerPn(receivedOrderData[4]);
        if (!product) {
          this.isCode[index_order - 1][index_product_received_order] =
            receivedOrderData[3];
        } else {
          //メーカ入力
          this.maker_ids[index_order - 1][index_product_received_order] =
            product.maker_id;
          //メーカで絞り込んだproductListをセット
          this.$set(
            this.productOptions[index_order - 1],
            index_product_received_order,
            this.makerFilterdSelectItem(
              this.maker_ids[index_order - 1][index_product_received_order]
            )
          );
          //商品入力
          this.params.orders[index_order - 1].product_received_orders[
            index_product_received_order
          ].product_id = product.id;
          //顧客と商品で絞り込んだcustomerProductListをセット
          this.$set(
            this.customerProductOptions[index_order - 1],
            index_product_received_order,
            this.customerProductFilterdSelectItem(
              this.params.customer_id,
              this.params.orders[index_order - 1].product_received_orders[
                index_product_received_order
              ].product_id
            )
          );
          // 顧客品番入力
          if (!customer_product) {
            this.isCustomerPn[index_order - 1][index_product_received_order] =
              receivedOrderData[4];
          } else {
            this.params.orders[index_order - 1].product_received_orders[
              index_product_received_order
            ].customer_product_id = customer_product.id;

            this.is_disabled[index_order - 1][index_product_received_order] =
              Object.keys(
                this.customerProductOptions[index_order - 1][
                  index_product_received_order
                ]
              ).length == 0;

            this.is_readonly[index_order - 1][index_product_received_order] =
              Object.keys(
                this.customerProductOptions[index_order - 1][
                  index_product_received_order
                ]
              ).length == 1;

            if (
              this.is_readonly[index_order - 1][index_product_received_order]
            ) {
              this.params.orders[index_order - 1].product_received_orders[
                index_product_received_order
              ].customer_product_id = Number(
                this.customerProductOptions[index_order - 1][
                  index_product_received_order
                ][0].value
              );
            }
          }

          //数量入力
          this.params.orders[index_order - 1].product_received_orders[
            index_product_received_order
          ].quantity = Number(receivedOrderData[5]);
          //単位入力
          this.units[index_order - 1][index_product_received_order] =
            product.unit;
          //単価入力
          this.params.orders[index_order - 1].product_received_orders[
            index_product_received_order
          ].selling_unit_price = Number(
            Number(receivedOrderData[6]).toFixed(2)
          );
        }
        //index_product_received_orderカウント
        index_product_received_order += 1;
      });
    };
    reader.onload = loadFunc;
    reader.readAsText(file);
  }

  //顧客注文追加(csv取り込み時)
  public appendOrderListCsv() {
    this.params.orders.push(new Orders());
    this.maker_ids.push([]);
    this.units.push([]);
    this.productOptions.push([]);
    this.customerProductOptions.push([]);
    this.customerPriceTagOptions.push([]);
    this.isCode.push([]);
    this.isCustomerPn.push([]);
    this.is_disabled.push([true]);
    this.is_readonly.push([false]);
    this.is_disabled_price_tag.push([true]);
    this.is_readonly_price_tag.push([false]);
    this.is_minus.push([]);
  }

  //全顧客注文削除(csv取り込み時)
  public deleteAllOrderList() {
    this.params.customer_id = 0;
    this.params.branch_id = null;
    this.isCustomer = true;
    this.maker_ids.splice(0);
    this.productOptions.splice(0);
    this.customerProductOptions.splice(0);
    this.customerPriceTagOptions.splice(0);
    this.units.splice(0);
    this.isCode.splice(0);
    this.isCustomerPn.splice(0);
    this.is_disabled.splice(0);
    this.is_readonly.splice(0);
    this.is_disabled_price_tag.splice(0);
    this.is_readonly_price_tag.splice(0);
    this.params.orders.splice(0);
    this.is_minus.splice(0);

    if (this.params.orders.length == 0) {
      this.params.orders.push(new Orders());
      this.maker_ids.push([]);
      this.units.push([]);
      this.productOptions.push([]);
      this.customerProductOptions.push([]);
      this.customerPriceTagOptions.push([]);
      this.isCode.push([]);
      this.isCustomerPn.push([]);
      this.is_disabled.push([true]);
      this.is_readonly.push([false]);
      this.is_disabled_price_tag.push([true]);
      this.is_readonly_price_tag.push([false]);
      this.is_minus.push([]);
    }
  }
}
